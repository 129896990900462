const state = () => ({
  upload_document: {
    loan_id: "",
    stage: "",
    product_name: "",
    brand_name: "",
    loan_amount: "",
    tenor: "",

    applicant: {
      remark: "",
      nrc_front: "",
      nrc_back: "",
      census_front: "",
      census_back: "",
      employee_card: "",
      selfie_photo: "",
      bill_photo: "",
      salary_slip: "",
      ward_recommendation_letter: "",
      company_hr_recommendation_letter: "",
      

      // land_permit: [],
      // agreement_contract: [],
      // other_payment_challan: [],
      // other_collateral: [],

      type: "applicant-document",
      completed_percent: 0,
    },
    guarantor_superior: {
      nrc_front: "",
      nrc_back: "",
      census_front: "",
      census_back: "",
      employee_card: "",
      selfie_photo: "",
      bill_photo: "",
      type: "guarantor-superior-document",
      completed_percent: 0,
    },
    // collateral: {
      // land_permit: "",
      // agreement_contract: "",
      // other_payment_challan: "",
      // type: "collateral-document",
      // completed_percent: 0,
    // },
  },
});

const getters = {};
const actions = {};
const mutations = {
  updateLoanID: (state, loan_id) => (state.upload_document.loan_id = loan_id),

  documentDetails: (state, responseData) => {
    if (responseData.applicant != null) {
      state.upload_document.stage = responseData.stage;
      // if (responseData.applicant.nrc_front) {
        state.upload_document.applicant.remark = responseData?.remark;
        state.upload_document.applicant.nrc_front = responseData?.applicant.nrc_front;
        state.upload_document.applicant.nrc_back = responseData?.applicant.nrc_back;
        state.upload_document.applicant.census_front = responseData?.applicant.census_front;
        state.upload_document.applicant.census_back = responseData?.applicant.census_back;
        state.upload_document.applicant.employee_card = responseData.applicant.employee_card;
        state.upload_document.applicant.selfie_photo = responseData.applicant.selfie_photo;
        state.upload_document.applicant.bill_photo = responseData.applicant.bill_photo;
        state.upload_document.applicant.salary_slip = responseData.applicant.salary_slip;
        state.upload_document.applicant.ward_recommendation_letter = responseData.applicant.ward_recommendation_letter;
        state.upload_document.applicant.company_hr_recommendation_letter =
          responseData.applicant?.company_hr_recommendation_letter;
        

        state.upload_document.loan_amount = responseData?.plan.unit_price;
        state.upload_document.tenor = responseData?.plan.tenure;
        // state.upload_document.remark = responseData.remark;
        state.upload_document.brand_name = responseData?.plan.brand_name;  
        state.upload_document.product_name = responseData?.plan.product_name;    

      state.upload_document.applicant.completed_percent =
        responseData.applicant.completed_percent;
    }

    if (responseData.guarantor_superior != null) {
        state.upload_document.guarantor_superior.nrc_front =
          responseData.guarantor_superior.nrc_front;
        state.upload_document.guarantor_superior.nrc_back =
          responseData.guarantor_superior.nrc_back;
        state.upload_document.guarantor_superior.census_front =
          responseData.guarantor_superior.census_front;
        state.upload_document.guarantor_superior.census_back =
          responseData.guarantor_superior.census_back;
        state.upload_document.guarantor_superior.employee_card =
          responseData.guarantor_superior.employee_card;
        state.upload_document.guarantor_superior.selfie_photo =
          responseData.guarantor_superior.selfie_photo;
        state.upload_document.guarantor_superior.bill_photo =
          responseData.guarantor_superior.bill_photo;

      state.upload_document.guarantor_superior.completed_percent =
        responseData.guarantor_superior.completed_percent;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
