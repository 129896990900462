const state = () => ({
  occupation_information: {
     loan_id: "",
     stage: '',
     department_name: "",
     position: "",
     
     years_of_service: "",
     months_of_service: "",
     monthly_basic_income: 0,
     additional_income: 0,
     total_income: 0,
     // occupation: "",
     salary_payday: "",
     hr_name:'',
     hr_phone: '',
     company_name: "",
     company_phone: "",
     bu_id: "",
  },
  company_address: {
     country_state_id: "",
     city_id: "",
     township_id: "",
     place_type: "Ward",
     ward_or_village_name: "",
     street: "",
     building_number: "",
     floor: "",
     // company_status: "Public Company"
  },
});

const getters = {
  getOccupationData(state) {
     return state.occupation_information;
  },

  getCompanyData(state) {
     return state.company_address;
  },
};
const actions = {};
const mutations = {
  updateLoanID: (state, loan_id) =>
     (state.occupation_information.loan_id = loan_id),

  updatePercent: (state, percent) => (state.completed_percent = percent),

  occupationDetails: (state, responseData) => {
     state.occupation_information.stage = responseData.stage

     state.occupation_information.department_name =
        responseData.department_name;
     state.occupation_information.position = responseData.position;
     
     // Bind New Field
        state.occupation_information.hr_name = responseData.hr_name;
        state.occupation_information.hr_phone = responseData.hr_phone;
     // Bind New Field
     
     state.occupation_information.bu_id = responseData.bu_id
     state.occupation_information.years_of_service =
        responseData.years_of_service;
     state.occupation_information.months_of_service =
        responseData.months_of_service;
        state.occupation_information.monthly_basic_income =
           responseData.monthly_basic_income;
        state.occupation_information.additional_income =
           responseData.additional_income;
        state.occupation_information.total_income = responseData.total_income;

     state.occupation_information.salary_payday = responseData.salary_payday;

     // Bind New Fields
      state.occupation_information.company_phone = responseData.company_phone;
      state.occupation_information.company_name = responseData.company_name;

     // if (responseData.address) {
        state.company_address.country_state_id = responseData.company_address?.country_state_id;
        state.company_address.city_id = responseData.company_address?.city_id;
        state.company_address.township_id = responseData.company_address?.township_id;
        state.company_address.place_type = responseData.company_address?.place_type;
        state.company_address.ward_or_village_name = responseData.company_address?.ward_or_village_name;
        state.company_address.building_number = responseData.company_address.building_number;
        state.company_address.street = responseData.company_address.street;
        state.company_address.floor = responseData.company_address.floor;
        // Bind New Field
        // state.company_address.bu_id = responseData.address.id;
        // if (responseData.address.company_status) {
        //    state.company_address.company_status = responseData.address.company_status;
        // }
     // }
  },

  
  addBuBrData: (state, payload) => {
     state.occupation_information.bu_id = payload.id;
  },
  addCompanyRegionData: (state, payload) => {
     state.company_address.country_state_id = payload.id;
  },
  addCompanyCityData: (state, payload) => {
     state.company_address.city_id = payload.id;
  },
  addCompanyTownshipData: (state, payload) => {
     state.company_address.township_id = payload.id;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
