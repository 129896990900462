import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import applicant from "./modules/applicant";
import occupation from "./modules/occupation";
import family from "./modules/family";
import upload from "./modules/upload";
import fetchOdooApi from './modules/fetch_odoo_api'

// initial state
let initialState = {
  applicant: applicant.state,
  occupation: occupation.state,
  family: family.state,
  upload: upload.state,
  fetchOdooApi: fetchOdooApi.state
};

const store = createStore({
  modules: {
    applicant,
    occupation,
    family,
    upload,
    fetchOdooApi
  },
  state: {},
  getters: {},
  actions: {},
  mutations: {
    reset(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
  },
  plugins: [createPersistedState()],
});

export default store;
