import { createRouter, createWebHistory } from "vue-router";
import EDLdocument from "../views/EDLdocument.vue";

const routes = [
  {
    path: "/",
    name: "EDLdocument",
    component: EDLdocument,
  },
  {
    path: "/ApplicantInformation",
    name: "ApplicantInformation",
    component: () =>
      import("../views/ApplicantInformation/ApplicantInformation.vue"),
  },
  {
    path: "/OccupationInformation",
    name: "OccupationInformation",
    component: () =>
      import("../views/OccupationInformation/OccupationInformation.vue"),
  },
  {
    path: "/GuarantorFamily",
    name: "GuarantorFamily",
    component: () => import("../views/GuarantorFamily/GuarantorFamily.vue"),
  },
  {
    path: "/UploadDocument",
    name: "UploadDocument",
    component: () => import("../views/UploadDocument/UploadDocument.vue"),
  },
  {
    path: "/SaveInformation",
    name: "SaveInformation",
    component: () => import("../components/Shared/SaveInformation.vue"),
  },
  {
    path: "/SaveApplyInformation",
    name: "SaveApplyInformation",
    component: () => import("../components/Shared/SaveApplyInformation.vue"),
  },
  {
    path: "/Back",
    name: "BackHome",
    component: () => import("../components/Shared/BackHome.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
