<template>
  <div class="header q-pl-sm">
    <q-icon name="west" @click="goBack" style="font-size: 25px" />
    <span class="title q-pl-sm">Electronic Device Loan(EDL) Document</span>
  </div>

  <div class="total_finish">
    <div class="q-py-md text-center text-white custom_font">
      <span>Total Finish</span>
      <br />
      <span>{{ total_finish_percent }}%</span>
    </div>
    <div class="q-pt-sm q-pb-md q-px-lg finish_progress">
      <div>
        <span class="text-white float-right">{{ total_finish_percent }}%</span>
        <q-linear-progress
          rounded
          white
          size="8px"
          :value="total_finish_percent / 100"
        />
      </div>
    </div>
  </div>
  <div class="q-pa-md">
    <div class="q-gutter-md">
      <div>
        <span class="text-negative q-pr-md">*</span>
        <span>Fullfill data to finance needs</span>
      </div>
      <div class="spacing row items-start">
        <q-card class="my_card q-mb-md" :style="{ opacity: (formPermissions?.permissions?.applicant_information?.length == 0 || (loan_stage == 'document_applied_by_customer' && !formPermissions?.permissions?.applicant_information))? 0.6 : 1 }"
          >
          <q-card-section @click="toApplicantInformation" :style="{ cursor: (formPermissions?.permissions?.applicant_information?.length == 0 || (loan_stage == 'document_applied_by_customer' && !formPermissions?.permissions?.applicant_information))? 'not-allowed' : 'default' }">
            <div>
              <img
                class="image"
                src="../../src/assets/Applicant_Information.jpg"
              />
            </div>
            <div class="text-center">
              <span class="text-green"
                >{{ edlLoan.applicant_information_percent }}%</span
              >
              <q-linear-progress
                rounded
                class="q-mt-sm q-mb-sm mini_progress"
                white
                size="6px"
                :value="edlLoan.applicant_information_percent / 100"
              />
              <span class="custom_size"
                >Applicant and Business Information</span
              >
            </div>
          </q-card-section>
        </q-card>

        <q-card class="my_card q-mb-md" :style="{ opacity: (formPermissions?.permissions?.occupation_information?.length == 0 || (loan_stage == 'document_applied_by_customer' && !formPermissions?.permissions?.occupation_information))? 0.6 : 1 }">
          <q-card-section @click="toOccupationInformation" :style="{ cursor: (formPermissions?.permissions?.occupation_information?.length == 0 || (loan_stage == 'document_applied_by_customer' && !formPermissions?.permissions?.occupation_information))? 'not-allowed' : 'default' }">
            <div>
              <img
                class="image"
                src="../../src/assets/Business_Information.jpg"
              />
            </div>
            <div class="text-center">
              <span class="text-green"
                >{{ edlLoan.applicant_occupation_percent }}%</span
              >
              <q-linear-progress
                rounded
                class="q-mt-sm q-mb-sm mini_progress"
                white
                size="6px"
                :value="edlLoan.applicant_occupation_percent / 100"
              />
              <span class="custom_size"
                >Occupation Information</span
              >
            </div>
          </q-card-section>
        </q-card>

        <q-card class="my_card q-mb-md" :style="{ opacity: (formPermissions?.permissions?.guarantor_family?.length == 0 || (loan_stage == 'document_applied_by_customer' && !formPermissions?.permissions?.guarantor_family))? 0.6 : 1 }" >
          <q-card-section @click="toGuarantorFamily" :style="{ cursor: (formPermissions?.permissions?.guarantor_family?.length == 0 || (loan_stage == 'document_applied_by_customer' && !formPermissions?.permissions?.guarantor_family))? 'not-allowed' : 'default' }">
            <div>
              <img class="image" src="../../src/assets/Gurantar_family.jpg" />
            </div>
            <div class="text-center">
              <span class="text-green"
                >{{ edlLoan.guarantor_superior_percent }}%</span
              >
              <q-linear-progress
                rounded
                class="q-mt-sm q-mb-sm mini_progress"
                white
                size="6px"
                :value="edlLoan.guarantor_superior_percent / 100"
              />
              <span class="custom_size">Guarantor Information</span>
            </div>
          </q-card-section>
        </q-card>

        <q-card class="my_card q-mb-md" :style="{ opacity: (formPermissions?.permissions?.bu_document_upload?.length == 0 || (loan_stage == 'document_applied_by_customer' && !formPermissions?.permissions?.bu_document_upload))? 0.6 : 1 }" >
          <q-card-section @click="toUploadDocument" :style="{ cursor: (formPermissions?.permissions?.bu_document_upload?.length == 0 || (loan_stage == 'document_applied_by_customer' && !formPermissions?.permissions?.bu_document_upload))? 'not-allowed' : 'default' }">
            <div>
              <img class="image" src="../../src/assets/Docunment_upload.jpg" />
            </div>
            <div class="text-center">
              <span class="text-green"
                >{{ edlLoan.application_document_percent }}%</span
              >
              <q-linear-progress
                rounded
                class="q-mt-sm q-mb-sm mini_progress"
                white
                size="6px"
                :value="edlLoan.application_document_percent / 100"
              />
              <span class="custom_size">Upload Documents</span>
            </div>
          </q-card-section>
        </q-card>
      </div>

      <div>
        <q-checkbox v-model="check_agree" label="" class="custom_checkbox" />
        <span
          >I accept
          <u class="text-blue" @click="goToTermsAndCondition"
            >terms and conditions</u
          >
          of Win Finance</span
        >
      </div>

      <div>
        <q-btn
          @click="apply"
          class="button_color"
          text-color="black"
          label="Apply"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Notify, Loading } from "quasar";
export default {
  data() {
    return {
      total_finish_percent: 0,
      check_agree: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      loan_id: this.$route.query.loan_id,
      token: this.$route.query.token,
      edlLoan: {},
      disabled_route: false,
      formPermissions: [],
      loan_stage: '',
      form_permission_id: '',
    };
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "BackHome",
        query: {
          loan_id: this.loan_id,
          token: this.token,
        },
      });
    },
    async getFormPermissions() {
      Loading.show();
      
      await axios
        .get(`${this.baseUrl}v1/loans/${this.loan_id}/form-permissions`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then(async (response) => {
          if(response.data.data != null) {
            this.formPermissions = response.data.data;
            console.log('Permissions', this.formPermissions);
            this.form_permission_id = response.data.data.id;
          }
          if(this.$store.getters["fetchOdooApi/getStateList"]?.length != 0 && 
          this.$store.getters["fetchOdooApi/getCityList"]?.length != 0 && 
          this.$store.getters["fetchOdooApi/getTownshipList"]?.length != 0) {
            Loading.hide();
          }
          await this.getEDLLoan()
        })
        .catch((error) => {
          Loading.hide();
          Notify.create({
            type: "negative",
            position: "top",
            message: error.message,
            timeout: 1500,
          });
        });
    },
    async getEDLLoan() {
      Loading.show();
      await axios
        .get(`${this.baseUrl}v1/edl-loans/${this.loan_id}`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          console.log('res', response.data.data);
          this.edlLoan = response.data.data;
          this.loan_stage = response.data.data.loan_stage
          this.total_finish_percent =
            (this.edlLoan.applicant_information_percent +
              this.edlLoan.applicant_occupation_percent +
              this.edlLoan.guarantor_superior_percent +
              this.edlLoan.application_document_percent) /
              4;
            
          Loading.hide();
        })
        .catch((error) => {
          Loading.hide();
          Notify.create({
            type: "negative",
            message: error.message,
            position: "top",
            timeout: 1500,
          });
        });
    },
    toApplicantInformation() {
      if(this.formPermissions?.permissions?.applicant_information?.length == 0 || (this.loan_stage == 'document_applied_by_customer' && !this.formPermissions?.permissions?.applicant_information)) {
        return
      }else if (this.disabled_route == false) {
        this.$store.commit("applicant/updateLoanID", this.loan_id);
        this.$router.push({
          name: "ApplicantInformation",
          query: {
            loan_id: this.loan_id,
            token: this.token,
            applicant_information_url: this.edlLoan.applicant_information_url,
          },
        });
      } else {
        Notify.create({
          type: "negative",
          position: "top",
          message: "You can't go this page when applying process!",
          timeout: 1500,
        });
      }
    },
    toOccupationInformation() {
      if(this.formPermissions?.permissions?.occupation_information.length == 0 || (this.loan_stage == 'document_applied_by_customer' && !this.formPermissions?.permissions?.occupation_information)) {
        return
      }else if (this.disabled_route == false) {
        this.$store.commit("occupation/updateLoanID", this.loan_id);
        this.$router.push({
          name: "OccupationInformation",
          query: {
            loan_id: this.loan_id,
            token: this.token,
            applicant_occupation_url: this.edlLoan.applicant_occupation_url,
          },
        });
      } else {
        Notify.create({
          type: "negative",
          position: "top",
          message: "You can't go this page when applying process!",
          timeout: 1500,
        });
      }
    },
    toGuarantorFamily() {
      if(this.formPermissions?.permissions?.guarantor_family?.length == 0 || (this.loan_stage == 'document_applied_by_customer' && !this.formPermissions?.permissions?.guarantor_family)) {
        return
      }else if (this.disabled_route == false) {
        this.$store.commit("family/updateLoanID", this.loan_id);
        this.$router.push({
          name: "GuarantorFamily",
          query: {
            loan_id: this.loan_id,
            token: this.token,
            guarantor_superior_url: this.edlLoan.guarantor_superior_url,
          },
        });
      } else {
        Notify.create({
          type: "negative",
          position: "top",
          message: "You can't go this page when applying process!",
          timeout: 1500,
        });
      }
    },
    toUploadDocument() {
      if(this.formPermissions?.permissions?.bu_document_upload?.length == 0 || (this.loan_stage == 'document_applied_by_customer' && !this.formPermissions?.permissions?.bu_document_upload)) {
        return
      } else if (this.disabled_route == false) {
        this.$store.commit("upload/updateLoanID", this.loan_id);
        this.$router.push({
          name: "UploadDocument",
          query: {
            loan_id: this.loan_id,
            token: this.token,
            application_document_url: this.edlLoan.application_document_url,
          },
        });
      } else {
        Notify.create({
          type: "negative",
          position: "top",
          message: "You can't go this page when applying process!",
          timeout: 1500,
        });
      }
    },
    apply() {
      if (this.total_finish_percent != 100) {
        Notify.create({
          type: "negative",
          position: "top",
          message: "Please fill the all data to complete 100%!",
          timeout: 1500,
        });
      } else {
        if (this.check_agree == true) {
          let parameters = {
            stage: "document_applied_by_customer",
            form_permission_id: this.form_permission_id?? ''
          };
          Loading.show();
          axios
            .put(`${this.baseUrl}v1/cl-loans/${this.loan_id}`, parameters, {
              headers: {
                Authorization: `Bearer ${this.token}`,
              },
            })
            .then((response) => {
              this.disabled_route = true;
              this.$router.push({
                name: "SaveApplyInformation",
                query: {
                  loan_id: this.loan_id,
                  token: this.token,
                },
              });
              Notify.create({
                type: "positive",
                position: "top",
                message: response.data.message,
                timeout: 1500,
              });
              Loading.hide();
            })
            .catch((error) => {
              Loading.hide();
              Notify.create({
                type: "negative",
                position: "top",
                message: error.message,
                timeout: 1500,
              });
            });
        } else {
          Notify.create({
            type: "negative",
            position: "top",
            message: "You must agree terms and conditions!",
            timeout: 1500,
          });
        }
      }
    },
    goToTermsAndCondition() {
      location.href =
        "https://winfinance-demo.umgclouds.com/information/terms-and-conditions";
    },
  },
  created() {
    
    this.getFormPermissions();
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  height: 55px;
  position: fixed;
  z-index: 1;
  padding-top: 17px;
  background: linear-gradient(120.26deg, #f4d9a9 35.44%, #cbcbbc 75.7%);
}
.title {
  font-size: 16px;
  font-weight: bold;
}
.total_finish {
  padding-top: 55px;
  background-color: #005198;
}
.custom_font {
  font-size: 16px;
}
.finish_progress {
  background-color: rgba(255, 255, 255, 0.15);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}
.q-linear-progress {
  color: #008000;
  background: #fff;
}
.my_card {
  width: 47%;
  border-radius: 20px;
}
.spacing {
  justify-content: space-between !important;
}
.q-card__section--vert {
  padding: 10px !important;
}
.image {
  width: 100%;
}
.custom_size {
  font-size: 12px;
}
.mini_progress {
  width: 75%;
  margin: 2% 13%;
}
.custom_checkbox {
  margin-left: -10px;
}
.button_color {
  width: 100%;
  height: 50px;
  border-radius: 8px;
  margin-bottom: 60px;
  background: linear-gradient(120.26deg, #f4d9a9 35.44%, #cbcbbc 75.7%);
}
</style>